import React, { useEffect, useState } from "react";

// img
import batteryCharging from "../../../../assets/navbars/topnavbar/battery-full-charging.svg";
import batteryFull from "../../../../assets/navbars/topnavbar/battery-full.svg";
import batteryGood from "../../../../assets/navbars/topnavbar/battery-good.svg";
import batteryLow from "../../../../assets/navbars/topnavbar/battery-low.svg";
import batteryEmpty from "../../../../assets/navbars/topnavbar/battery-empty.svg";

const Battery = ({ context }) => {
	const [batteryInformation, setBatteryInformation] = useState(null);

	useEffect(() => {
		if (navigator.getBattery) {
			navigator.getBattery().then((battery) => {
				setBatteryInformation(battery);

				// Optional: You can also add event listeners to update state when battery status changes
				battery.addEventListener("levelchange", () => setBatteryInformation({ ...battery }));
				battery.addEventListener("chargingchange", () => setBatteryInformation({ ...battery }));
			});
		} else {
			// Handle cases where getBattery is not supported
			console.warn("Battery API is not supported by this browser.");
			setBatteryInformation({
				charging: false,
				level: 1, // Default to full battery if information is unavailable
			});
		}
	}, []);

	if (!batteryInformation) {
		return <div>Battery information is not available</div>; // Or another fallback UI
	}

	return (
		<div className="batterie-container">
			{batteryInformation.charging ? (
				<div className="settings-img">
					<img src={batteryCharging} alt="battery-charging" className={context === "details" ? "details" : ""} />
					{context === "details" ? <p>{batteryInformation.level * 100}%</p> : ""}
				</div>
			) : (
				<>
					{(batteryInformation.level === 1 || batteryInformation.level >= 0.9) && (
						<div className="settings-img">
							<img src={batteryFull} alt="battery-level" className={context === "details" ? "details" : ""} />
							{context === "details" ? <p>{batteryInformation.level * 100}%</p> : ""}
						</div>
					)}
					{batteryInformation.level < 0.9 && batteryInformation.level >= 0.5 && (
						<div className="settings-img">
							<img src={batteryGood} alt="battery-level" className={context === "details" ? "details" : ""} />
							{context === "details" ? <p>{batteryInformation.level * 100}%</p> : ""}
						</div>
					)}
					{batteryInformation.level < 0.5 && batteryInformation.level >= 0.2 && (
						<div className="settings-img">
							<img src={batteryLow} alt="battery-level" className={context === "details" ? "details" : ""} />
							{context === "details" ? <p>{batteryInformation.level * 100}%</p> : ""}
						</div>
					)}
					{batteryInformation.level < 0.2 && (
						<div className="settings-img">
							<img src={batteryEmpty} alt="battery-level" className={context === "details" ? "details" : ""} />
							{context === "details" ? <p>{batteryInformation.level * 100}%</p> : ""}
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default Battery;