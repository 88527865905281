import Desktop from "./components/desktop/Desktop";

const App = () => {
	return (
		<div className="App">
			<Desktop />
		</div>
	);
};

export default App;
